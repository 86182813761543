import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_en_backendmdx = graphql`
        query PageQuery_service_en_backendmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/backend/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Back-end",
  "path": "/service/backend",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "en",
  "icon": "bx-cloud",
  "tags": ["engineer", "architecture", "senior"],
  "experience": [{
    "title": "Streaming: Virtual Limbs",
    "task": "Developer",
    "tags": ["lead", "developer"],
    "outcome": ["Quicker than video", "Built foundation for streams", "Ready for data-intensive work"],
    "link": ["https://www.fifa.com/technical/football-technology/news/limb-tracking-technology-offers-new-array-of-possibilities", "https://www.fifa.com/technical/football-technology/news/semi-automated-offside-tech-aids-referees-and-fans-at-fifa-club-world-cup-tm"],
    "image": ["./images/backend/bs-fifa-stream-limb-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "Video & Audio Matching",
    "task": "Technical Support / QA",
    "tags": ["developer", "qa"],
    "outcome": ["Automated sync pipeline", "AI-supported audio shift", "174 batch-generated videos"],
    "link": ["https://www.youtube.com/playlist/?list=PLIqZSskBjtdstFEHiK0ioq3NZMLcFMy_6"],
    "image": ["./images/backend/nmt-ns-julia-01.jpg"],
    "copyright": "Nick & Simon B.V."
  }, {
    "title": "Streaming: Positional Data",
    "task": "Senior Engineer",
    "tags": ["senior", "engineer", "developer"],
    "outcome": ["Cost reduction", "Used in TV broadcasts", "Configurable input + modification"],
    "link": ["https://www.beyondsports.nl/solutions", "https://www.youtube.com/watch?v=LRDmbMPKzRE"],
    "image": ["./images/backend/bs-sky-mnf-01.jpg"],
    "copyright": "Beyond Sports B.V. / Sky Group Ltd"
  }, {
    "title": "Video Frame Compositor",
    "task": "Pipeline Developer / QA",
    "tags": ["developer", "qa"],
    "outcome": ["Back-end + desktop UI", "Near-realtime iteration", "Full frame composition pipeline", "Used for various TV shows (see refs)"],
    "link": ["https://www.npostart.nl/tumblies/KN_1713957", "https://vimeo.com/49843036"],
    "image": ["./images/backend/nmt-kro-tumblies-01.jpg"],
    "copyright": "il Luster"
  }, {
    "title": "Multi-Stream Broadcast",
    "task": "Senior Engineer",
    "tags": ["senior", "engineer", "developer"],
    "outcome": ["Cost reduction", "Synchronisation between streams", "Broadcasting of multiple streams"],
    "link": ["https://www.beyondsports.nl/solutions"],
    "image": ["./images/backend/bs-nhl-stream-multi-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "Crypto Tracking",
    "task": "Developer",
    "tags": ["developer"],
    "outcome": ["Conversion between financial assets", "Better understanding of trading costs"],
    "link": ["https://github.com/Cheesebyte/cheesebyte-holdmycrypto"],
    "image": ["./images/backend/cb-holdmycrypto-01.png"],
    "copyright": "Cheesebyte"
  }]
};
const layoutProps = {
  PageQuery_service_en_backendmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "a-stable-back-end",
      "style": {
        "position": "relative"
      }
    }}>{`A stable back-end`}<a parentName="h1" {...{
        "href": "#a-stable-back-end",
        "aria-label": "a stable back end permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`As a back-end developer, I took the lead on several streaming projects. People were enthusiastic about the resulting performance.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The speed at which data travels allows `}{`[company]`}{` to broadcast entire games in real-time ...`}</p>
      <p parentName="blockquote">{`In fact, at times they build a delay, so as not to show their visualisation ahead of actual broadcast footage.`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://blog.infront.sport/player-positional-tracking-experiences"
        }}>{`Source article`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}>{`Skills`}<a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`10+ years of back-end experience`}</li>
      <li parentName="ul">{`Experienced in producing maintainable code`}</li>
      <li parentName="ul">{`Experienced in building code with security in mind`}</li>
      <li parentName="ul">{`Experience with high-performance and scalable code`}</li>
    </ul>
    <h2 {...{
      "id": "technical",
      "style": {
        "position": "relative"
      }
    }}>{`Technical`}<a parentName="h2" {...{
        "href": "#technical",
        "aria-label": "technical permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`I apply `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/SOLID"
        }}>{`SOLID`}</a>{` rules`}</li>
      <li parentName="ul">{`I follow `}<a parentName="li" {...{
          "href": "https://owasp.org/www-project-top-ten/"
        }}>{`OWASP`}</a>{` guidelines`}</li>
      <li parentName="ul">{`Microservices, containerisation`}</li>
      <li parentName="ul">{`.NET (Framework, Core, 5+), Node.js, ASP.NET`}</li>
      <li parentName="ul">{`Happy to use Azure, AWS or other cloud platforms`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      